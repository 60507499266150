import React, { useEffect } from "react";
import Footer from "../../Layout/Footer";
import { CustomButton } from "../../Atoms/Button";
import EastIcon from "@mui/icons-material/East";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import { getLocalStorageWithExpiry } from "../../../lib/utils";

const Welcome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getLocalStorageWithExpiry("token");

    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);
  return (
    <div className="overflow-hidden">
      <div className="relative">
        {/* Navbar - Ensure this has a higher z-index */}
        <div className="relative z-[50] ">
          <Header />
        </div>

        {/* Gradient Background - Set this to be behind the Navbar */}
        <div
          className="absolute w-[51.8vw] h-[51.8vw] right-[-17vw]  sm:w-[61.875vw] sm:h-[61.875vw]  sm:right-[-30vw] sm:top-1/3 sm:-translate-y-1/2  lg:w-[61.875vw] lg:h-[61.875vw] lg:left-[-25vw] lg:top-[-5vw] overflow-hidden z-[10]"
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, #FF7C02 -50%, rgba(180, 55, 149, 0) 88.02%)",
            pointerEvents: "none",
          }}
        />

        {/* Main Content */}
        <div className=" flex flex-col justify-center  sm:min-h-[90vh] ">
          <div
            className="relative flex justify-center sm:items-center
       sm:h-[100%] mt-[15.313vw] pb-[15vw] sm:pb-0 sm:mb-[8.065vw] sm:mt-[8.065vw] lg:mt-[5.139vw] lg:h-auto "
          >
            <div className="blured-card p-[5.625vw] rounded-[2.5vw] w-[88%] sm:w-[64.785vw] sm:px-[5.108vw] sm:py-[3.108vw] sm:rounded-[1.075vw] sm:min-h-[24.097vw] lg:w-[57.639vw] lg:rounded-[0.556vw] lg:px-[4.514vw] lg:py-[2.5vw] lg:min-h-[24.097vw] z-[100]">
              <div className="flex flex-col items-center gap-[5vw] text-center sm:gap-[2vw] lg:gap-[1.5vw]">
                <h2>Welcome to the community! </h2>
                <p>Thank You for Your Purchase!</p>
                <p>
                  Your payment was successful, and we're excited to have you on
                  board!
                </p>
                <p>
                  To activate your account, please check your email for the
                  activation link we've just sent you. Click the link to
                  complete your registration.
                </p>
                <p>
                  If you don't see the email, be sure to check your spam/junk
                  folder. Still need help? Contact our support team!
                </p>

                <Link to="/">
                  <p className="cursor-pointer uppercase flex items-center gap-[.3vw] text-primaryOrange">
                    Login <EastIcon />
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Welcome;
