import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFoundPage from "./Pages/NotFound";
import { lazy } from "react";
import "./styles/global.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { Suspense } from "react";
import axios from "axios";
import ProtectedRoute from "./Components/Layout/ProtectedRoute";
import { AppNotificationsContextProvider } from "./helper/app_notifications.context";

import Welcome from "./Components/Auth/Welcome";
import { default as LoginPage } from "./Pages/Auth/Login";
import ErrorPage from "./Pages/Error";
import { getLocalStorageWithExpiry } from "./lib/utils";
import { UserContextProvider } from "./lib/context/userContext";
export const ForgotPasswordPage = lazy(() =>
  import("./Pages/Auth/ForgotPassword")
);
export const SignupPage = lazy(() => import("./Pages/Auth/Signup"));
export const ResetPasswordPage = lazy(() =>
  import("./Pages/Auth/ResetPassword")
);
export const CoursesPage = lazy(() => import("./Pages/Dashboard/Courses"));
export const GlossaryPage = lazy(() => import("./Pages/Dashboard/Glossary"));
export const GlossaryCollectionPage = lazy(() =>
  import("./Pages/Dashboard/Glossary/collection")
);
export const TradeJournalPage = lazy(() =>
  import("./Pages/Dashboard/TradeJournal")
);
export const SettingsPage = lazy(() => import("./Pages/Dashboard/Settings"));
export const CourseDetailsPage = lazy(() =>
  import("./Pages/Dashboard/Courses/courseDetails")
);
export const SingleVideoPage = lazy(() =>
  import("./Pages/Dashboard/Glossary/singleVideo")
);
export const CategoriesPage = lazy(() =>
  import("./Pages/Dashboard/Courses/categories")
);
export const NotificationPage = lazy(() =>
  import("./Pages/Dashboard/Notification")
);

export const DashboardPage = lazy(() => import("./Pages/Dashboard"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // staleTime: Infinity,
    },
  },
});

const handleLogout = () => {
  localStorage.clear();
  window.location.href = "/";
};

function App() {
  axios.interceptors.request.use(
    (config) => {
      const token = getLocalStorageWithExpiry("token");
      if (token) {
        config.headers["Authorization"] = `${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        console.log("Unauthorized access - Logging out...");
        alert(error.response.data?.message);
        handleLogout();
      }

      return Promise.reject(error);
    }
  );

  return (
    <UserContextProvider>
      <AppNotificationsContextProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider />
          <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route exact path="/" element={<LoginPage />} />
                <Route exact path="/signup" element={<SignupPage />} />
                <Route
                  exact
                  path="/forgot-password"
                  element={<ForgotPasswordPage />}
                />
                <Route
                  exact
                  path="/reset-password"
                  element={<ResetPasswordPage />}
                />
                <Route exact path="/welcome" element={<Welcome />} />

                <Route
                  exact
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <DashboardPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/dashboard/notification"
                  element={
                    <ProtectedRoute>
                      <NotificationPage />
                    </ProtectedRoute>
                  }
                />

                {/* courses */}
                <Route
                  exact
                  path="/categories"
                  element={
                    <ProtectedRoute>
                      <CategoriesPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/categories/courses"
                  element={
                    <ProtectedRoute>
                      <CoursesPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/categories/course-details"
                  element={
                    <ProtectedRoute>
                      <CourseDetailsPage />
                    </ProtectedRoute>
                  }
                />

                <Route
                  exact
                  path="/videos"
                  element={
                    <ProtectedRoute>
                      <GlossaryPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/videos/most-liked"
                  element={
                    <ProtectedRoute>
                      <GlossaryCollectionPage type="most liked" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/videos/watchlist"
                  element={
                    <ProtectedRoute>
                      <GlossaryCollectionPage type="watchlist" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/videos/latest-drop"
                  element={
                    <ProtectedRoute>
                      <GlossaryCollectionPage type="latest-drop" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/videos/video"
                  element={
                    <ProtectedRoute>
                      <SingleVideoPage />
                    </ProtectedRoute>
                  }
                />

                <Route
                  exact
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <SettingsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/pdf-resources"
                  element={
                    <ProtectedRoute>
                      <TradeJournalPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/error" element={<ErrorPage />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </QueryClientProvider>
      </AppNotificationsContextProvider>
    </UserContextProvider>
  );
}

export default App;
