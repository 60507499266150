import React from "react";
import Footer from "../Footer";
import { Ticker } from "../../Auth/Login";
import Header from "../Header";

const AuthLayout = ({ children, showTicker = false }) => {
  return (
    <div className="overflow-hidden lg:overflow-auto">
      <div className="relative">
        {/* Navbar - Ensure this has a higher z-index */}
        <div className="relative z-[50] ">
          <Header />
        </div>
        {showTicker && (
          <div className="mt-[2vw]">
            <Ticker />
          </div>
        )}

        {/* Gradient Background - Set this to be behind the Navbar */}
        <div
          className="absolute w-[51.8vw] h-[51.8vw] right-[-17vw]  sm:w-[61.875vw] sm:h-[61.875vw]  sm:right-[-30vw] sm:top-1/3 sm:-translate-y-1/2  lg:w-[61.875vw] lg:h-[61.875vw] lg:left-[-25vw] lg:top-[-5vw] overflow-hidden z-[10]"
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, #FF7C02 -50%, rgba(180, 55, 149, 0) 88.02%)",
            pointerEvents: "none",
          }}
        />

        {/* Main Content */}
        <div>
          <div
            className="relative flex justify-center sm:items-center
           sm:min-h-[65vh] mt-[25.313vw] pb-[15vw] sm:pb-0 sm:mb-[8.065vw] sm:mt-[8.065vw] lg:mt-[3.139vw] lg:h-auto "
          >
            <div className="blured-card p-[5.625vw] rounded-[2.5vw] w-[88%] sm:w-[64.785vw] sm:px-[5.108vw] sm:py-[3.108vw] sm:rounded-[1.075vw] sm:min-h-[24.097vw] lg:w-[57.639vw] lg:rounded-[0.556vw] lg:px-[4.514vw] lg:py-[2.5vw] lg:min-h-[24.097vw] z-[100]">
              {children}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default AuthLayout;
