"use client";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const StyledButton = styled(Button)(
  ({ theme, color, variant, typeTextHoverColor }) => ({
    borderColor: "#ff7c02",
    color: theme.palette[color]?.main || "#fff",
    background:
      variant === "contained" ? "rgba(255, 255, 255, 0.05)" : "transparent",
    textTransform: "uppercase",
    boxShadow: "none",
    borderRadius: "0.5vw",
    fontSize: "1.111vw",
    // fontSize: "1.38vw",
    padding: variant === "text" ? "0" : "0.4vw 1.59vw",
    fontFamily: "var(--outfit)",
    fontWeight: 400,
    "&:hover": {
      borderColor: variant === "outlined" ? "#ff7c02" : "#ff7c02",
      color:
        variant === "text"
          ? typeTextHoverColor || "#FF7C02"
          : theme.palette[color]?.main || "#fff",
      background: variant === "text" ? "transparent" : "transparent",
    },

    // var(--custom-gradient)
    [theme.breakpoints.down("md")]: {
      fontSize: "1.882vw",
      padding: variant === "text" ? "0vw" : "0.4vw 1.59vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.75vw",
      padding: variant === "text" ? " 0vw" : "1.3vw 3.59vw",
      borderRadius: "1vw",
    },
  })
);

export const CustomButton = ({
  children,
  borderColor,
  background,
  color,
  variant,
  endIcon,
  onClick,
  className,
  ...restProps
}) => {
  return (
    <StyledButton
      variant={variant}
      color={color}
      // endIcon={endIcon}
      endIcon={
        endIcon && (
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {React.cloneElement(endIcon, {
              sx: {
                fontSize: {
                  xs: "5vw",
                  sm: "2vw",
                  md: "1.5vw",
                  lg: "1.5vw",
                  xl: "1.5vw",
                },
              },
            })}
          </span>
        )
      }
      onClick={onClick}
      className={className}
      {...restProps}
    >
      {children}
    </StyledButton>
  );
};
