import React from "react";
import { East } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import { CustomButton } from "../Atoms/Button";

const NotFound = () => {
  return (
    <div className="relative h-[100vh] overflow-hidden">
      {/* Header with higher z-index */}
      <div className="relative z-50 bg-[#111]">
        <Header />
      </div>

      {/* Background Image */}
      <img
        src="/assets/images/not-found-bg.png"
        alt="bg"
        className="h-full w-full object-cover absolute inset-0 z-0"
      />

      {/* Text Content */}
      <div className="relative z-50 flex flex-col pt-[10vw] items-center h-full text-center text-white ">
        <h4 className="mb-4 font-semibold">Seems like you are lost...</h4>
        <p className="font-bold text-[14vw] leading-none">404</p>
        <p className="text-lg mt-2 mb-6">Error, page not found</p>
        <Link to="/dashboard">
          <CustomButton variant="outlined" endIcon={<East />}>
            RETURN TO HOMEPAGE
          </CustomButton>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
