import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import MuiInput from "../../Atoms/MuiInput";
import { MyCircularProgress } from "../../Atoms/CustomCircularProgress";
import { CustomButton } from "../../Atoms/Button";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { signIn } from "../../../lib/api/auth";
import { enqueueSnackbar } from "notistack";
import useAppNotifications from "../../../helper/app_notifications.context";
import { setLocalStorageWithExpiry } from "../../../lib/utils";
import useUserContext from "../../../lib/context/userContext";

const loginSchema = yup.object({
  email: yup.string().required("email is required"),
  password: yup.string().required("password is required"),
});

const Login = () => {
  const { setAppNotification } = useAppNotifications();
  const [loginData, setLoginData] = useState({});
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const { loginUser } = useUserContext();

  const handleDataChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setError((prev) => ({ ...prev, [e.target.name]: "" }));
  };

  const { mutate, isLoading, isPending } = useMutation({
    mutationKey: ["signIn"],
    mutationFn: signIn,
    onSuccess: (response) => {
      const token = response?.headers?.["authorization"];
      if (token) {
        setLocalStorageWithExpiry("token", token, 1400 * 60 * 1000);
      }

      loginUser(response?.data?.data);
      enqueueSnackbar("Successfully logged in", { variant: "success" });

      if (response?.data?.data?.subscriptionStatus?.isActive) {
        navigate("/dashboard");
      } else {
        setAppNotification([
          {
            message: `⚠️ Your Membership is Inactive! Reactivate now to regain access to all premium content and features. Don't miss out!`,
            createdAt: new Date(),
            actionLink: "/settings#plans",
            actionLabel: "View Membership",
          },
        ]);
        navigate("/settings");
      }
    },
    onError: (error) => {
      enqueueSnackbar(
        error?.response?.data?.message || "Something went wrong",
        { variant: "error" }
      );
    },
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginSchema.validate(loginData, { abortEarly: false });
      mutate(loginData);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setError(validationErrors);
    }
  };

  return (
    <>
      <form
        className="flex flex-col gap-[7.5vw] sm:gap-[2.3vw] lg:gap-[2.5vw]"
        onSubmit={handleFormSubmit}
      >
        <center className="flex flex-col gap-[3vw] px-[3vw] sm:gap-0">
          <h2>Login</h2>
          <p>
            Welcome, please login or &nbsp;
            <Link to="https://notjustatrade.com" target="_blank">
              <span className="text-primaryOrange cursor-pointer hover:underline">
                sign up
              </span>
            </Link>
            &nbsp; for a new account.
          </p>
        </center>
        <MuiInput
          placeholder="Email"
          type="text"
          name="email"
          value={loginData?.email || ""}
          onChange={handleDataChange}
          fullWidth
          borderColor="#fff"
          placeholderColor="#fff"
          className="text-white"
          error={error.email}
        />
        <MuiInput
          placeholder="Password"
          type="password"
          name="password"
          value={loginData?.password || ""}
          onChange={handleDataChange}
          fullWidth
          borderColor="#fff"
          placeholderColor="#fff"
          className="text-white"
          error={error.password}
        />
        <Link to="/forgot-password">
          <p className="text-primaryOrange">Forget password?</p>
        </Link>
        {isLoading || isPending ? (
          <MyCircularProgress />
        ) : (
          <CustomButton
            variant="text"
            endIcon={<EastIcon />}
            className="w-fit"
            type="submit"
          >
            Login
          </CustomButton>
        )}
      </form>
    </>
  );
};

export default Login;

export const Ticker = () => {
  return (
    <div className="text-center bg-custom-gradient py-[3vw] px-[2vw] sm:py-[1.4vw] sm:px-[2vw] lg:py-[1.267vw] lg:px-[3vw]">
      {/* <marquee> */}
      <h5>
        Note : If you are existing user kindly reset your password&nbsp;
        <Link to="/forgot-password">
          <span className="underline cursor-pointer">click here</span>.
        </Link>
        &nbsp;We have upgraded our application for a seamless user experience.
      </h5>
      {/* </marquee> */}
    </div>
  );
};
