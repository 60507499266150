import axios from "axios";

export const signIn = async ({ email, password }) => {
  try {
    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}user/sign-in`,
      data: { email: email.trim(), password: password.trim() },
    };

    const response = await axios(config);
    return response;
  } catch (error) {
    console.error("Error response:", error.response);
    throw error;
  }
};

export const signUp = async (data) => {
  try {
    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: ` ${process.env.REACT_APP_BASE_URL}sign-up`,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error("Error response:", error.response);
    throw error;
  }
};

export const forgotPassword = async ({ email }) => {
  try {
    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}send-update-password-link`,
      data: { email },
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error("Error response:", error.response);
    throw error;
  }
};

export const generatePassword = async (data) => {
  try {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}update-password`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = await axios(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const setProfile = async (data) => {
  try {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}set-profile`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = await axios(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};
