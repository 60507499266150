"use client";
import { IconButton, Input, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const CustomInput = styled(Input)(
  ({ theme, placeholderColor, borderColor }) => ({
    "&:before": {
      borderBottom: "1px solid ",
      borderBottomColor: borderColor || "#fff", // Default line color
    },
    "&:after": {
      borderBottom: "2px solid",
      borderBottomColor: borderColor || "#fff", // Focused line color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: borderColor || "#fff", // Default underline color
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: borderColor || "#fff", // Focused underline color
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "2px solid ",
      borderBottomColor: borderColor || "#fff", // Hover line color
    },
    "& input::placeholder": {
      color: placeholderColor || "#fff", // Placeholder color
      opacity: 1, // Ensure the placeholder color is not affected by the opacity
    },

    "& input": {
      color: "#fff",
      // paddingBottom: "1.1vw",
      fontSize: "1.11vw",
      fontFamily: "outfit",
    },

    "& input[type='search']": {
      paddingBottom: ".5vw",
    },

    "& input[type='search']::-webkit-search-cancel-button": {
      appearance: "none", // Resets to default
      "-webkit-appearance": " none",
    },

    [theme.breakpoints.down("md")]: {
      "& input": {
        paddingBottom: "1vw",
        fontSize: "1.613vw",
        fontFamily: "outfit",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& input": {
        // paddingBottom: "4vw",
        fontSize: "3.75vw",
        fontFamily: "outfit",
      },
    },
  })
);

const MuiInput = ({
  className,
  placeholderColor,
  borderColor,
  placeholder,
  helperText,
  error,
  ...restProps
}) => {
  return (
    <div>
      <CustomInput
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        borderColor={borderColor}
        {...restProps}
      />
      <div className="flex items-center gap-[1.8vw] sm:gap-[.5vw] lg:gap-[.5vw]">
        {error && (
          <>
            <ErrorIcon className="text-[4vw] sm:text-[1.8vw] lg:text-[1vw] text-red-500" />
            <p className="text-red-500 text-[3.3vw] sm:text-[1.5vw] lg:text-[1vw]">
              {error}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default MuiInput;

export const PasswordTypeInput = ({
  error,
  label,
  errormsg,
  name,
  value,
  onChange,
  placeholder,
  required,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <>
      <MuiInput
        type={showPassword ? "text" : "password"}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        placeholder={placeholder}
        fillWidth
        errormsg={errormsg}
        label={label}
        required={required}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? (
                <Visibility sx={{ fontSize: 18 }} />
              ) : (
                <VisibilityOff sx={{ fontSize: 18 }} />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
};

export const CustomSearch = ({ setSearchKeyword }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleClearInput = () => {
    setSearchValue("");
    setSearchKeyword("");
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") setSearchKeyword("");
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      setSearchKeyword(searchValue); // Pass the search value to the parent
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-[100%] blured-card flex items-center rounded-[1.538vw] sm:py-[.2vw] lg:py-[.2vw] sm:rounded-[0.417vw] lg:pr-[1vw]">
      <form onSubmit={handleSearchSubmit} className=" w-[100%]">
        <CustomInput
          placeholder="Search"
          name="search"
          value={searchValue}
          onChange={handleSearchChange}
          fullWidth
          borderColor="transparent"
          placeholderColor="#CDCDCD"
          className="text-white"
          endAdornment={
            searchValue && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={handleClearInput}
                >
                  <CloseIcon style={{ color: "#CDCDCD" }} fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="search">
                <SearchIcon
                  style={{ color: searchValue ? "#fff" : "#CDCDCD" }}
                  fontSize="small"
                />
              </IconButton>
            </InputAdornment>
          }
        />
      </form>
    </div>
  );
};
