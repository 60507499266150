import { Navigate, useLocation } from "react-router-dom";
import { getLocalStorageWithExpiry } from "../../../lib/utils";
import { useQuery } from "@tanstack/react-query";
import { getProfileInfo } from "../../../lib/api/user";
import { Backdrop, CircularProgress } from "@mui/material";
import useUserContext from "../../../lib/context/userContext";

const ProtectedRoute = ({ children }) => {
  const token = getLocalStorageWithExpiry("token");
  let user = getLocalStorageWithExpiry("user");
  const { updateUser, setUser, user: userValue } = useUserContext();

  if (user && !userValue) {
    setUser(user);
  }

  const location = useLocation();
  const isEnabled = user && !user?.subscriptionStatus?.isActive;
  const { isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getProfileInfo({ id: user?._id }),
    enabled: !!isEnabled,
    onSuccess: (res) => {
      user = res?.data;
      user && updateUser(user);
    },
  });

  if (!token) {
    return <Navigate to="/" replace />;
  } else if (
    !user?.subscriptionStatus?.isActive &&
    location.pathname != "/settings" &&
    location.pathname != "/dashboard/notification"
  ) {
    return <Navigate to="/settings" replace />;
  }

  if (isEnabled && isLoading)
    return (
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  return children;
};

export default ProtectedRoute;
