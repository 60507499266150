import React from "react";
import { East } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";

const Error = ({ data }) => {
  return (
    <div>
      <Header />
      <div className="flex justify-center  items-center my-[18vw] sm:my-[15vh] lg:my-[8vw]">
        <div className="flex flex-col items-center gap-[5vw] blured-card p-[5.625vw] rounded-[2.5vw] w-[88%] sm:w-[64.785vw] sm:gap-[2vw] sm:px-[5.108vw] sm:py-[3.108vw] sm:rounded-[1.075vw] sm:min-h-[24.097vw] lg:w-[57.639vw] lg:rounded-[0.556vw] lg:px-[4.514vw] lg:py-[2.5vw] lg:min-h-[24.097vw] z-[100]">
          <h2 className="text-center">{data?.heading}</h2>
          <p className="text-center">{data?.text}</p>
          <Link to={data?.redirectLink}>
            <p className="cursor-pointer uppercase flex items-center gap-[.3vw] text-primaryOrange">
              {data?.redirectText} <East />
            </p>
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Error;
