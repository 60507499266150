import React, { useEffect } from "react";
import AuthLayout from "../../../Components/Layout/AuthLayout";
import Login from "../../../Components/Auth/Login";
import { useNavigate } from "react-router-dom";
import { getLocalStorageWithExpiry } from "../../../lib/utils";

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getLocalStorageWithExpiry("token");

    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <AuthLayout showTicker={true}>
      <Login />
    </AuthLayout>
  );
};

export default LoginPage;
