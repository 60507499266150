import React from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { pathname } = useLocation();

  return (
    <div className="px-[6.25vw] pb-[10vw] | sm:px-[6.94vw] sm:pb-[3vw] |lg:px-[6.94vw] lg:pb-[3vw] ">
      <hr className="mb-[6.26vw] sm:mb-[3vw] lg:mb-[1.5vw]" />

      <div className="flex flex-col gap-[6.25vw] sm:gap-0 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex gap-[5vw] sm:gap-[2vw] h-fit text-[#acacac]">
          <p className="p1">Follow us</p>
          <Link
            to="https://www.instagram.com/njatchucks?igsh=ajF4NTF2N3FzZHRy"
            target="_blank"
          >
            <p className="p1 text-primaryOrange">@NJATChucks</p>
          </Link>
          <Link
            to="https://www.instagram.com/njatburton/profilecard/?igsh=ZjJkaDd4M3JiN3c4"
            target="_blank"
          >
            <p className="p1 text-primaryOrange">@NJATBurton</p>
          </Link>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex items-center justify-start gap-[2vw] sm:justify-end sm:gap-[1.4vw] lg:gap-[.7vw]">
            <Link to="https://www.youtube.com/@notjustatrade" target="_blank">
              <div className="relative h-[6.031vw] w-[6.031vw] sm:h-[2.594vw] sm:w-[2.594vw] lg:w-[2.083vw] lg:h-[2.083vw]">
                <img
                  src="/assets/images/icons/youtube-c.png"
                  alt="YouTube"
                  className="w-full h-full object-contain"
                />
              </div>
            </Link>
            <Link
              to="https://www.instagram.com/notjustatrade/?hl=en"
              target="_blank"
            >
              <div className="relative h-[6.031vw] w-[6.031vw] sm:w-[2.594vw] sm:h-[2.594vw] lg:w-[2.083vw] lg:h-[2.083vw]">
                <img
                  src="/assets/images/icons/instagram-c.png"
                  alt="Instagram"
                  className="w-full h-full object-contain"
                />
              </div>
            </Link>
          </div>

          <Link to="/contact">
            <p
              className={`p1 text-[#acacac] pr-[2vw] sm:hidden lg:hover:text-white cursor-pointer ${
                pathname === "/contact" ? "text-white sm:text-white" : ""
              }`}
            >
              Contact Us
            </p>
          </Link>
        </div>
      </div>

      <hr className="my-[7vw] sm:my-[2.7vw] border-t border-[#acacac] lg:my-[1.5vw]" />

      <div className="flex flex-col justify-between sm:flex-row">
        <p className="p1">NJAT © {currentYear} All Rights Reserved.</p>
        <div className="font-normal mt-[5vw] grid grid-cols-2 gap-y-[4vw] sm:mt-0 sm:flex sm:justify-between sm:gap-[4vw] sm:text-[1.8vw] lg:text-[1.38vw] h-fit">
          <Link to="/t&c">
            <p
              className={`p1 text-[#acacac] lg:hover:text-white cursor-pointer ${
                pathname === "/t&c" ? "text-white sm:text-white" : ""
              }`}
            >
              Terms & Condition
            </p>
          </Link>
          <Link to="/privacy-policy">
            <p
              className={`p1 text-[#acacac] lg:hover:text-white cursor-pointer ${
                pathname === "/privacy-policy" ? "text-white sm:text-white" : ""
              }`}
            >
              Privacy Policy
            </p>
          </Link>
          <Link to="/contact">
            <p
              className={`hidden sm:flex p1 text-[#acacac] lg:hover:text-white cursor-pointer ${
                pathname === "/contact" ? "text-white sm:text-white" : ""
              }`}
            >
              Contact Us
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
