import React from "react";
import Error from "../../Components/Auth/Error";
import { useLocation } from "react-router-dom";

const data1 = {
  heading: "PASSWORD RESET LINK EXPIRED",
  text: (
    <>
      We noticed you were trying to reset your password. For security reasons,
      password reset links are only valid for 15 minutes.
      <br />
      <br /> Please click “Forgot Password” to receive a new link and set up
      your password to log in. <br />
      <br /> For any issues, feel free to reach out to us at&nbsp;
      <a href="mailto:admin@notjustatrade.com">
        <span className="text-primaryOrange">admin@notjustatrade.com.</span>
      </a>
    </>
  ),
  redirectLink: "/forgot-password",
  redirectText: "Forgot password",
};

const data2 = {
  heading: "PASSWORD LINK EXPIRED",
  text: (
    <>
      The link you tried to generate password has expired, as it’s only valid
      for 15 minutes for security reasons.
      <br />
      <br /> Please click “Forgot Password” to receive a new link and set up
      your password to log in. <br />
      <br /> For any issues, feel free to reach out to us at&nbsp;
      <a href="mailto:admin@notjustatrade.com">
        <span className="text-primaryOrange">admin@notjustatrade.com.</span>
      </a>
    </>
  ),
  redirectLink: "/forgot-password",
  redirectText: "Forgot password",
};

const data3 = {
  heading: "YOU ARE ALREADY A MEMBER",
  text: (
    <>
      We noticed you attempted to sign up, but it appears you already have an
      account with us. Any money debited via Stripe will be refunded to you.
      <br />
      <br /> Please log in to your user dashboard. If you don’t have an active
      plan, you’ll be redirected to the plans page, where you can activate your
      plan. <br />
      <br /> For any issues, feel free to reach out to us at&nbsp;
      <a href="mailto:admin@notjustatrade.com">
        <span className="text-primaryOrange">admin@notjustatrade.com.</span>
      </a>
    </>
  ),
  redirectLink: "/dashboard",
  redirectText: "TAKE ME TO DASHBOARD",
};

const ErrorPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  return (
    <div>
      <Error
        data={
          type === "passwordUpdateLinkVerificationFailed"
            ? data1
            : type === "setProfileLinkVerificationFailed"
            ? data2
            : type === "userAlreadyExists"
            ? data3
            : ""
        }
      />
    </div>
  );
};

export default ErrorPage;
