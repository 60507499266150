import { createContext, useContext, useState } from "react";

const Context = createContext();

export function AppNotificationsContextProvider({ children }) {
  const [appNotification, setAppNotification] = useState([]);

  return (
    <Context.Provider value={{ appNotification, setAppNotification }}>
      {children}
    </Context.Provider>
  );
}

export default function useAppNotifications() {
  return useContext(Context);
}
