import axios from "axios";

export const getProfileInfo = async ({ id }) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}profile/${id}`,
    };

    const res = await axios(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updateProfileInfo = async ({ id, data }) => {
  try {
    let config = {
      method: "PATCH",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}profile/${id}`,
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        currentPassword: data.currentPassword,
        password: data.password,
      },
    };

    const res = await axios(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const updateProfilePic = async ({ id, profilePicture }) => {
  try {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}profile/picture/${id}`,

      data: { profilePicture },
    };

    const res = await axios(config);
    return res.data;
  } catch (error) {
    throw error;
  }
};
