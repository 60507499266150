"use client";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="w-full px-[6.25vw] py-[5vw] flex justify-between items-center sm:px-[5.37vw] sm:py-[2.37vw] lg:py-[1vw] lg:px-[6.94vw] z-50 backdrop-blur-2xl ">
      <div>
        <Link to="/">
          <img
            src="/assets/images/njat_logo.png"
            alt="njat-logo"
            className={`relative transition-all duration-500 ${
              isScrolled
                ? "w-[20vw] h-[6vw] sm:w-[8vw] sm:h-[2.5vw] lg:w-[9vw] lg:h-[2.7vw]"
                : "w-[40.55vw] h-[11.5vw] sm:w-[17.4vw] sm:h-[4.9vw] lg:w-[15vw] lg:h-[4.4vw] "
            }`}
          />
        </Link>
      </div>
    </div>
  );
};

export default Header;
