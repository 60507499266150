import { CircularProgress } from "@mui/material";

export function MyCircularProgress() {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#FF7C02" />
            <stop offset="100%" stopColor="#ff7c02" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        style={{ color: "#FF7C02" }}
        sx={{
          fontSize: {
            xs: 6,
            sm: 10,
            md: 10,
            lg: 30,
            xl: 42,
          },
        }}
      />
    </>
  );
}
